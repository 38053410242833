import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import moment from 'moment';

import '../styles/dashboard.css';
import { CgDollar } from "react-icons/cg";
import { FaCubes } from "react-icons/fa";
import { GiWallet } from "react-icons/gi";
import { IoIosPeople } from "react-icons/io";
import { BsFillCreditCard2BackFill, BsCloudDownloadFill } from "react-icons/bs";

import BGS from '../assets'
import { getAccount } from '../../database/actions/account';
import TradingViewWidget from '../../LiveTrading/templates/TradingViewWidget';


const Dashboard = () => {
  const { account } = useSelector((state) => state.accounts);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
  const dispatch = useDispatch()



  useEffect(() => {
    dispatch(getAccount(user.result._id));
  }, [dispatch, user.result._id])


  const imgBGStyles = {
    backgroundImage: `url(${BGS.bg1})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  return (
    <>
      <div className="dashboard">
        <div className="dashboard-container">
          <div className="dashboad-header" style={imgBGStyles}><h2>Dashboard</h2></div>

          <div className="dashboard-body">


            <div className="dashboard-details">
              <ul className="status">
                <li className={(account?.status === "Verified") ? "verified" : "Demo"}>
                  <h4>Account Status:</h4>
                  <p>{account?.status}</p>
                </li>
                <li className={(account?.level === "Diamond") ? "diamond" :
                  (account?.level === "Silver") ? "silver" :
                    (account?.level === "Gold") ? "gold" :
                      (account?.level === "Platinum") ? "platinum" : "basic"}>
                  <h4>Account Level:</h4>
                  <p>{account?.level}</p>
                </li>
              </ul>
              <ul className='wallet-balance'>
                <li>
                  <div className="bal-left">
                    <p>Main Account</p>
                    <h4>${account?.transaction?.balance?.mainAccount}</h4>
                  </div>
                  <div className="bal-right">
                    <p><CgDollar /></p>
                  </div>
                </li>
                <li>
                  <div className="bal-left">
                    <p>Profit Balance</p>
                    <h4>${account?.transaction?.balance?.interestBonus}</h4>
                  </div>
                  <div className="bal-right">
                    <p><GiWallet /></p>
                  </div>
                </li>
                <li>
                  <div className="bal-left">
                    <p>total investments</p>
                    <h4>${account?.transaction?.balance?.invested}</h4>
                  </div>
                  <div className="bal-right">
                    <p><FaCubes /></p>
                  </div>
                </li>
                <li>
                  <div className="bal-left">
                    <p>total deposit</p>
                    <h4>${account?.transaction?.balance?.deposited}</h4>
                  </div>
                  <div className="bal-right">
                    <p><BsFillCreditCard2BackFill /></p>
                  </div>
                </li>
                <li>
                  <div className="bal-left">
                    <p>total withdraw</p>
                    <h4>${account?.transaction?.balance?.withdrawn}</h4>
                  </div>
                  <div className="bal-right">
                    <p><BsCloudDownloadFill /></p>
                  </div>
                </li>
                <li>
                  <div className="bal-left">
                    <p>referral Bonus</p>
                    <h4>${account?.transaction?.balance?.referralBonus}</h4>
                  </div>
                  <div className="bal-right">
                    <p><IoIosPeople /></p>
                  </div>
                </li>
              </ul>
            </div>
            <div className="tradeProgress">
              <h3>{account?.trade?.stock} Trade in Progress</h3>
              <p>{account?.trade?.progress}% Completed</p>
              <div className="statusBar">
                <div className="imageBar">
                  <p style={{ "--i" : account?.trade?.progress}}></p>
                </div>
              </div>
            </div>
            <div className="infomat">
              <h4>Trade Binary Options and digital options with us. Pick the type of options that best suits your trading style and profitability expectations.</h4>
            </div>
            <div className="tradeWidg">
              <TradingViewWidget />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Dashboard