import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";

import { transfer, getAccount } from '../../database/actions/account';
import '../styles/transfer.css';

const Transfer = () => {
  const { account } = useSelector((state) => state.accounts);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
  const [transValue, setTransValue] = useState({ wallet: '', reciever: '', amount: '', charge: '', payable: '', total: '', text: '' });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // useEffect(() => {
  //   dispatch(getAccount(id));
  // }, [])

  useEffect(() => {
    let percent = 0;
    let payable = 0;
    percent = (transValue.amount * 0.0125).toFixed(3);
    payable = Number(transValue.amount) + Number(percent);
    setTransValue({ ...transValue, total: payable, charge: percent, payable: payable });
  }, [transValue.amount]);

  const handleTransf = async (e) => {
    e.preventDefault();

    let balance = Number(user.result?.transaction?.balance?.mainAccount)
    balance = Number(user.result?.transaction?.balance?.mainAccount) - Number(transValue.payable)

    const formData = {
      "username": `${transValue.reciever}`, "charge": `+ ${transValue.charge}`,
      "payable": transValue.payable, "amount": Number(transValue.amount),
      "details": `Transfer`, "status": `Pending`,
      "balance": balance
    }

    if (user.result?.transaction?.balance?.mainAccount < transValue.payable) {
      setTransValue({ text: "Low Balance" });
    }
    else if (user.result?.transaction?.balance?.mainAccount > transValue.payable) {
      setTransValue({ text: " " });
      dispatch(transfer(user.result._id, formData, navigate, user));
    }
  }
  return (
    <>
      <div className="transfer beforeWas">
        <div className="trans-header zl1 pad-top" >
          <h2>Transfer</h2>
        </div>
        <div className="trans-cont zl1">
          <form onSubmit={handleTransf}>
            <select name="wallet" id="wallet" value={transValue.wallet} onChange={(e) => setTransValue({ ...transValue, wallet: e.target.value })} required>
              <option value="Personal Account">Main Account (${`${user.result?.transaction?.balance?.mainAccount}`})</option>
            </select>
            <div className="form-cont transfer-input">
              <input type="text" name="username" id="username" value={transValue.reciever} onChange={(e) => setTransValue({ ...transValue, reciever: e.target.value })} required />
              <div className="underline"></div>
              <label htmlFor="username">Recievers Address</label>
            </div>
            <div className="form-cont transfer-input">
              <input type='number' name="amount" id="amount" value={transValue.amount} onChange={(e) => setTransValue({ ...transValue, amount: e.target.value })} required />
              <div className="underline"></div>
              <label htmlFor="amount">Amount <span>gas fee (+1.25%) </span></label>
            </div>
            <div className="total">
              <h3>${transValue.total ? transValue.total : '0'} <span>Total</span></h3>

            </div>
            <button type="submit" className='btn-hover'>Transfer</button>
          </form>
        </div>
      </div>
    </>
  )
}

export default Transfer