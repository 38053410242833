import React, { useEffect, useState } from "react";
import {useDispatch, useSelector} from "react-redux";
import { useLocation, useNavigate, Link } from "react-router-dom";

import { BsFillChatLeftTextFill } from 'react-icons/bs'

import '../styles/header.css'
import Mobile from './Mobile'
import Desktop from './Desktop'
import { getAccount } from "../../database/actions/account";
import TickerTapeWidget from "../../LiveTrading/templates/TickerTapeWidget";


const Header = ({ liveC, setLiveC }) => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
  const { account, isLoading } = useSelector((state) => state.accounts);
  const [navbar, setNavBar] = useState(false)
  const [unread, setUnread] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const logout = () => {
    navigate('/')
    dispatch({ type: 'LOGOUT' })
    setUser(null)
  }

  useEffect(() => {
    const pathname = location.pathname.split("/");
    if(pathname.includes('liveChat')){
      setLiveC(true)
    } else {
      setLiveC(false)
    }
  }, [location.pathname])

  useEffect(() => {
    const token = user?.token

    setUser(JSON.parse(localStorage.getItem('profile')))
  }, [location]);

  const changeBG = () => {
    if (window.scrollY >= 100) {
      setNavBar(true)
    } else {
      setNavBar(false)
    }
  }

  window.addEventListener('scroll', changeBG)

  useEffect(() => {
    if (user) {
      dispatch(getAccount(user?.result?._id));
    }
  }, [dispatch, user])

  useEffect(() => {
    if (account) {
      const prev = account?.logs?.support
      const msgs = account?.liveChats
      const sendersMsg = msgs.filter((item) => item.sender === "Support")
      const current = sendersMsg.length
      const unread = Number(current) - Number(prev)
      setUnread(unread)
    }
  }, [account])

  return (
    <>
    <Desktop logout={logout} userDets={user} navbar={navbar} unread={unread} account={account}/>
    <Mobile logout={logout} user={user} navbar={navbar} unread={unread} account={account}/>
    <TickerTapeWidget />

    </>
  )
}

export default Header