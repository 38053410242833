import React, { useState } from 'react';
import { Link } from 'react-router-dom'

import '../styles/welcome.css'

const Welcome = () => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
  return (
    <>
      <div className="introSetup">
        <h2>Your Profile is Almost Complete</h2>
        <p>You have successfully created your profile. Now you can proceed to use our platform</p>
        <ul className="introduct">
          <li  className='depost'><Link to={`/${user?.result?.userName}/deposit/${user?.result?._id}`}>Deposit Now</Link></li>
          <li className='addAcc'><Link to={`/${user?.result?.userName}/profile-setting/${user?.result?._id}`}>Complete Set Up</Link></li>
          <li className='goDash'><Link to={`/${user?.result?.userName}/dashboard/${user?.result?._id}`}>Go to Dashboard</Link></li>
        </ul>
      </div>
    </>
  )
}

export default Welcome