import React from 'react'
import { Link } from 'react-router-dom'

import '../styles/updates.css'

const Updates = () => {
  return (
    <>
     <div className="updates">
        <div className="heading pad-top">
          <h2>New Updates </h2>
        </div>
      <div className="updates-cont">
        <ul>
          <li>
            <h3>New payment options</h3>
            <p>We added a few more payment options other than BTC</p>
          </li>
          <li>
            <h3>Referral Program</h3>
            <p>We've added referral program to our listings. Earn bonus with every new person who signs in with your referallID and earn money everytime they invest</p>
          </li>
          <li>
            <h3>Password Reset page Fixed</h3>
            <p>for those having a hard time changing password can now visit the password reset page to change their password and if you in general forgot your password, and are logged out, contact our <Link to="/support">Support team</Link> and we'll have send an OTP to trit email they can use to get back into their account and change to their preferred password </p>
          </li>
          <li>
            <h3>Gas fee Reduction</h3>
            <p>We've Reduced our gas fee, now users can withdraw and transfer funds at a cheaper cost</p>
          </li>
        </ul>
      </div>
     </div>
    </>
  )
}

export default Updates