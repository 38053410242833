import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import "../styles/support.css";

import { BiSend } from "react-icons/bi";
import { FaFacebookSquare, FaTiktok } from "react-icons/fa";
import { TiSocialInstagram } from "react-icons/ti";
import { SiGmail } from "react-icons/si";

const Support = () => {
  const navigate = useNavigate();
  const initialState = {
    name: '',
    email: '',
    reason: '',
  }
  const [formData, setFormData] = useState(initialState);

  const onSubmit = () => {
    navigate('/')
  }

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }
  return (
    <>
      <div className="contactCont">
        <div className="contactUs">
          <div className="contactIntro pad-top">
            <h2>Contact Us</h2>
            <div className="contactIMG"></div>
            <p></p>
          </div>
          <div className="contactDetails">
            <p>
              Have any questions or compaints or enquiry ?. send us a message{" "}
            </p>
            <form onSubmit={onSubmit}>
              <div className="form-cont">
                <input type="text" name="name" id="name" onChange={handleChange} required />
                <div className="underline"></div>
                <label htmlFor="firstName">Full Name:</label>
              </div>
              <div className="form-cont">
                <input type="email" name="email" id="email" onChange={handleChange} required />
                <div className="underline"></div>
                <label htmlFor="firstName">Email:</label>
              </div>
                <textarea placeholder="reason" name="reason" id="reason" cols="30" rows="5" maxLength="50" onChange={handleChange} required
                ></textarea>
              <button type="submit">Send <BiSend /></button>
            </form>
          </div>
          <div className="socials">
            <p>reach out on all platforms;</p>
            <ul>
              <li>
                <Link to="http://facebook.com">
                  <FaFacebookSquare /> Facebook
                </Link>
              </li>
              <li>
                <Link to="http://instagram.com" >
                  <TiSocialInstagram /> Instagram
                </Link>
              </li>
              <li>
                <Link to="http://tiktok.com" >
                  <FaTiktok /> Tik-Tok
                </Link>
              </li>
              <li>
                <Link to="mailto:triaengles@gmail.com" >
                  <SiGmail />Gmail
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Support;
