import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from "react-router-dom";
import { ColorRing } from 'react-loader-spinner'

const Forml = ({ signin, signup, isSignUp, setIsSignUp }) => {
  const errState = {
    mail: '', pw: ' ', ax: '', err: ''
  }

  const initialState = {
    firstName: '',
    lastName: '',
    email: '',
    userName: '',
    country: '',
    number: '',
    city: '',
    password: '',
    confirmPassword: ''
  }

  const countries = ['Afghanistan', 'Albania', 'Algeria', 'Andorra', 'Angola', 'Antigua and Barbuda', 'Argentina', 'Armenia', 'Australia', 'Austria', 'Azerbaijan',
'Bahamas', 'Bahrain', 'Bangladesh', 'Barbados', 'Belarus', 'Belgium', 'Belize', 'Benin', 'Bhutan', 'Bolivia', 'Bosnia and Herzegovina', 'Botswana', 'Brazil', 'Brunei', 'Bulgaria', 'Burkina Faso', 'Burundi',
'Cabo Verde', 'Cambodia', 'Cameroon', 'Canada', 'Central African Republic', 'Chad', 'Chile', 'China', 'Colombia', 'Comoros', 'Congo', 'Costa Rica', 'Croatia', 'Cuba', 'Cyprus', 'Czech Republic (Czechia)', `Côte d'Ivoire`, 
'Denmark', 'Djibouti', 'Dominica', 'Dominican Republic', 'DR Congo', 'Ecuador', 'Egypt', 'El Salvador', 'Equatorial Guinea', 'Eritrea', 'Estonia', 'Eswatini', 'Ethiopia', 'Fiji', 'Finland', 'France',
'Gabon', 'Gambia', 'Georgia', 'Germany', 'Ghana', 'Greece', 'Grenada', 'Guatemala', 'Guinea', 'Guinea-Bissau', 'Guyana', 'Haiti', 'Holy See', 'Honduras', 'Hungary',
'Iceland', 'India', 'Indonesia', 'Iran', 'Iraq', 'Ireland', 'Israel', 'Italy', 'Jamaica', 'Japan', 'Jordan', 'Kazakhstan', 'Kenya', 'Kiribati', 'Kuwait', 'Kyrgyzstan', 'Laos', 'Latvia', 'Lebanon', 'Lesotho', 'Liberia', 'Libya', 'Liechtenstein', 'Lithuania', 'Luxembourg',
'Madagascar', 'Malawi', 'Malaysia', 'Maldives', 'Mali', 'Malta', 'Marshall Islands', 'Mauritania', 'Mauritius', 'Mexico', 'Micronesia', 'Moldova', 'Monaco', 'Mongolia', 'Montenegro', '	Morocco', 'Mozambique', 'Myanmar',
'Namibia', 'Nauru', 'Nepal', 'Netherlands', 'New Zealand', 'Nicaragua', 'Niger', 'Nigeria', 'North Korea', 'North Macedonia', 'Norway', 'Oman', 'Pakistan', 'Palau', 'Panama', 'Papua New Guinea', 'Paraguay', 'Peru', 'Philippines', 'Poland', 'Portugal', 'Qatar', 'Romania', 'Russia', 'Rwanda',
'Saint Kitts & Nevis', 'Saint Lucia', 'Samoa', 'San Marino', 'Sao Tome & Principe', 'Saudi Arabia', 'Senegal', 'Serbia', 'Seychelles', 'Sierra Leone', 'Singapore', 'Slovakia', 'Slovenia', 'Solomon Islands', 'Somalia', 'South Africa', 'South Korea', 'South Sudan', 'Spain', 'Sri Lanka', 'St. Vincent & Grenadines', 'State of Palestine', 'Sudan', 'Suriname', 'Sweden', 'Switzerland', 'Syria',
'Tajikistan', 'Tanzania', 'Thailand', 'Timor-Leste', 'Togo', 'Tonga', 'Trinidad and Tobago', 'Tunisia', 'Turkey', 'Turkmenistan', 'Tuvalu', 'Uganda', 'Ukraine', 'United Arab Emirates', 'United Kingdom', 'United States', 'Uruguay', 'Uzbekistan', 'Vanuatu', 'Venezuela', 'Vietnam', 'Yemen', 'Zambia', 'Zimbabwe']

  const { isLoading } = useSelector((state) => state.auth);
  const [formData, setFormData] = useState(initialState);
  const [errMSG, setErrMSG] = useState(errState);
  const [siren, setSiren] = useState(false)
  const [sirenP, setSirenP] = useState(false)
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (errMSG.err === `401`) {
      setErrMSG({ ...errMSG, mail: `User don't exist` })
      setSiren(true)
      setSirenP(false)
    } else if (errMSG.err === `402`) {
      setErrMSG({ ...errMSG, pw: `incorect password` })
      setSiren(false)
      setSirenP(true)
    } else if (errMSG.err === `500`) {
      setErrMSG({ ...errMSG, ax: `poor connection!!!` })
      setSiren(false)
      setSirenP(false)
    } else {
      setErrMSG({ mail: ``, pw: ``, ax: `` })
      setSiren(false)
      setSirenP(false)
    }
  }, [errMSG.err])

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSignUp) {
      dispatch(signup(formData, navigate));
    }
    else if ((!isSignUp)) {
      dispatch(signin(formData, navigate, setErrMSG));
    }
  }

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  const switchMode = () => {
    setIsSignUp((prevIsSignUp) => !prevIsSignUp);
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <h6>{errMSG.ax}</h6>
        {
          isSignUp && (
            <>
              <div className="form-cont">
                <input type="text" name="firstName" id="firstName" onChange={handleChange} required />
                <div className="underline"></div>
                <label htmlFor="firstName">First Name</label>
              </div>
              <div className="form-cont">
                <input type="text" name="lastName" id="lastName" onChange={handleChange} required />
                <div className="underline"></div>
                <label htmlFor="lastName">Last Name</label>
              </div>
              <div className="form-cont">
                <input type="text" name='userName' id='userName' onChange={handleChange} required />
                <div className="underline"></div>
                <label htmlFor="userName">Username</label>
              </div>
              <div className="form-cont">
                <select name='country' id='country' onChange={handleChange} required>
                  <option value=''>Country</option>
                  {
                    countries.map((country, index) => (
                      <option value={country} key={index}>{country}</option>
                    ))
                  }
                </select>
              </div>
              <div className="form-cont">
                <input type="text" name='city' id='city' onChange={handleChange} required />
                <div className="underline"></div>
                <label htmlFor="city">City</label>
              </div>
              <div className="form-cont">
                <input type="number" name='number' id='number' onChange={handleChange} required />
                <div className="underline"></div>
                <label htmlFor="number">Phone Number</label>
              </div>
            </>
          )
        }
        <div className={"form-cont" + (siren ? " invalid" : " ")}>
          <input type="email" name="email" id="email" onChange={handleChange} required />
          <div className="underline"></div>
          <label htmlFor="email">Email</label>
          <h6>{errMSG.mail}</h6>
        </div>

        <div className={"form-cont" + (sirenP ? " invalid" : " ")}>
          <input type="password" name="password" id="password" onChange={handleChange} required />
          <div className="underline"></div>
          <label htmlFor="password">Password</label>
          <h6>{errMSG.pw}</h6>
        </div>
        {
          isSignUp &&
          <div className="form-cont">
            <input type="password" name="confirmPassword" id="confirmPassword" onChange={handleChange} required />
            <div className="underline"></div>
            <label htmlFor="confirmPassword">Confirm Password</label>
          </div>
        }
        {
          isSignUp && (
            <div className='tnc'>
              <input type="checkbox" name="tnc" id="tnc" required />
              <label htmlFor="tnc" className='tnc'> I agree with <Link to='/auth'> Privacy & Policy</Link>,<Link to='/auth'>Terms & Condition</Link></label>
            </div>
          )
        }
        <button className='btn-hover' type="submit">{isLoading ? <ColorRing visible={true} width="25px" height="25px" ariaLabel="blocks-loading" colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']} /> : (isSignUp ? 'Sign Up' : 'Sign In')}  </button>
        <p onClick={switchMode}> {
          isSignUp ? 'Have an account? Sign In' : "Don't Have an account? Sign Up"
        }</p>
      </form>
    </>
  )
}

export default Forml