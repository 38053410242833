import React, { useState, useEffect } from 'react';

import "../styles/deposit.css";
import DepositPP from './DepositPP';
import PaymentGate from './PaymentGate';

const Deposit = () => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));

  const [depositPP, setDepositPP] = useState(false);
  const [depositGG, setDepositGG] = useState(false);


  const [depositValue, setDepositValue] = useState({
    currency: '',
    amount: '',
    charges: '',
    payable: '',
    text: '',
    paymentProof: '',
    wallet: '',
    network: ''
  });

  let balance = Number(user.result?.transaction?.balance?.mainAccount)
  balance = Number(user.result?.transaction?.balance?.mainAccount) + Number(depositValue.payable)

  const formData = {
    "username": `${depositValue.currency} ${depositValue.network}`, "charge": `+ ${depositValue.charges}`,
    "payable": depositValue.payable, "amount": Number(depositValue.amount),
    "details": `Deposit`, "status": `Pending`,
    "balance": balance, "paymentProof": `${depositValue.paymentProof}`
  }


  const currencyValue = [{ value: 'USDT' }, { value: 'Bitcoin' }, { value: 'Ethereum' }]


  const Currencied = (value) => {
    setDepositValue({ ...depositValue, currency: value })
  }

  useEffect(() => {
    let percent = 0;
    let total = 0
      percent = (depositValue.amount * 0.005).toFixed(2);
      total = Number(depositValue.amount) + Number(percent);
    setDepositValue({ ...depositValue, payable: total, charges: percent });
  }, [depositValue.amount]);

  useEffect(() => {
    if (depositValue.currency === 'Bitcoin'){
      setDepositValue({ ...depositValue, wallet: "1GSU8hEQ8h2awehQspmSZkt7wqGxR6jmtU"})
    }
    else if (depositValue.currency === 'Ethereum'){
      setDepositValue({ ...depositValue, wallet: "0x86e4344660a10daa36d7aadc99ee1ccf7c981be2"})
    }
    else if (depositValue.currency === 'USDT'){
      setDepositValue({ ...depositValue, wallet: "TA65UmQgXbTjisCkhMHwcZy3an1qMGv1Fj"})
    }
  }, [depositValue.currency]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (depositValue.amount < 5){
      setDepositValue({ ...depositValue, text: "!!! Amount must be greater than $5" })
    }
    else if ( depositValue.currency === ''){
      setDepositValue({ ...depositValue, text: "!!! Please select a currency" })
    }
    else if ( depositValue.amount === ''){
      setDepositValue({ ...depositValue, text: "!!! Please Enter Amount" })
    }
    else if ((depositValue.amount > 5 || depositValue.amount !== '')   && depositValue.currency !== '') {
    setDepositPP(true);
    }
  }
  return (
    <>
    <div className="depo">
      <div className="deposit beforeWas">
        <div className="deposit-header pad-top">
        </div>
        <div className="deposit-container zl1">
          <div className="deposit-box ">
          <h2>Fund Your Account</h2>
            <p className="texts"> {depositValue.text}</p>
            <form onSubmit={handleSubmit}>
              <div className="form-cont transfer-input">
                <input type="number" name="amount" id="amount" value={depositValue.amount} onChange={(e) => setDepositValue({ ...depositValue, amount: e.target.value })} required />
                <div className="underline"></div>
                <label htmlFor="amount">Amount</label>
              </div>

              <ul className="currencied">
                <h4>Choose Payment Method from the list below</h4>
                {
                  currencyValue.map(option => (
                    <li key={option.value} onClick={() => Currencied(option.value)} className={(depositValue.currency.toLowerCase() === option.value.toLowerCase()) ? "selected" : " "}>
                      <h4>{option.value}</h4>
                    </li>
                  )
                  )}
              </ul>

              <ul className='network'>
                <h4>Select Network</h4>
                <select name="network" id="network" value={depositValue.network} onChange={(e) => setDepositValue({ ...depositValue, network: e.target.value })}>
                    <option value=" ">None</option>
                    <option value="ERC20">ERC20</option>
                    <option value="TRC20">TRC20</option>
                  </select>
              </ul>

              <button type="submit" className='btn-hover'> Deposit </button>
            </form>
          </div>
        </div>
        </div>
        <PaymentGate formData={formData} depositGG={depositGG} setDepositGG={setDepositGG} depositValue={depositValue} setDepositValue={setDepositValue} user={user} />
        <DepositPP depositPP={depositPP} setDepositPP={setDepositPP} depositValue={depositValue} setDepositGG={setDepositGG}/>

      </div>
    </>
  )
}

export default Deposit